<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <div style="width: 80%; max-width: 500px; margin-bottom: 40px;">
      <v-img :src="require(`@/assets/logo.png`)" />
    </div>
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="100%"
          class="px-5 py-3"
          style="text-align: right"
          noheader
        >
          <v-card-text class="text-center">
            <div v-if="passwordResetDone">
              <div style="width: 100%; margin-bottom: 10px;">
                <v-icon
                  size="54"
                  color="success"
                  v-text="'mdi-check'"
                />
              </div>
              <p>
                {{ $t('dialog.your-password-has-been-sent') }}
              </p>
              <p>
                {{ $t('dialog.if-it-did-not-work') }}
              </p>
            </div>

            <p v-else>
              {{ $t('dialog.your-password-reset-is-being-processed') }}
            </p>

            <v-btn
              large
              color="primary"
              depressed
              type="submit"
              @click="goToLogin"
            >
              {{ $t('go-to-login') }}
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  import userService from '@/shared/services/userService'

  export default {
    name: 'PagesLogin',

    data: () => ({
      passwordResetDone: false,
    }),
    mounted () {
      const resetId = this.$route.params.resetId
      if (resetId) {
        userService.resetPasswordConfirm(resetId).then(response => {
          this.passwordResetDone = true
        })
      }
    },

    methods: {
      goToLogin () {
        this.$router.push('/login', () => {})
      },
    },
  }
</script>
